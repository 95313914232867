@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: 'Poppins',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.error {
  color: orangered;
  font-size: 0.75rem;
  margin-top: 0.2rem;
  margin-left: 0.25rem;
  font-weight: 600;
}

.MuiAutocomplete-inputFocused {
  border-color: transparent !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: transparent !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: transparent !important;
}

body::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

/* customScrollbar.css */

.MuiDataGrid-virtualScroller {
  scrollbar-width: thin;
  scrollbar-color: #48a3dcc2 #deecf4;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 12px;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Hide scrollbar for all elements */
* {
  scrollbar-width: none; /* Firefox */
}

*::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}