.mainModal {
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.651);
  z-index: 1001;
  background: rgba(46, 46, 46, 0.25);
  box-shadow: 15px 15px 50px #00000029;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  cursor: pointer;
}

.mainModal2 {
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  /* z-index: 1001; */
}

.iconContainer {
  width: 14em;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 18px;
  cursor: pointer;
}


