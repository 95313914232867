body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiDataGrid-withBorderColor {
  border: none;
}

.css-ptkaw2-MuiDataGrid-root {
  background: white;
  border: none;
  border-color: transparent !important;
  border-radius: 15px !important;
}

.css-t89xny-MuiDataGrid-columnHeaderTitle {
  font-family: "Poppins";
  color: #8F8F8F;
  font-size: 13px;
}

.css-204u17-MuiDataGrid-main {
  /* padding-left: 15px;
  padding-right: 15px; */
}

.css-1iyq7zh-MuiDataGrid-columnHeaders {
  border-bottom: 3px solid #f3f3f4 !important;
  padding-left: 15px;
  padding-right: 15px;
}

.MuiDataGrid-row {
  border: none;
  background: #f8f8f8;
  margin: 17px;
  border-radius: 12px;
  border-color: transparent !important;
  font-family: "Poppins";
  color: #132843;
  font-size: 13px;
  padding: 8px;
}

.MuiDataGrid-cell {
  border-bottom: none !important;
}

.MuiDataGrid-cell:focus {
  outline: none !important;
}

/* .css-1lbgfbl-MuiResponsiveChart-container {
  background: #F2F2F2 !important;
  border-radius: 20px !important;
  margin: 10px !important;
} */

.css-aop0yh {
  background: white !important;
  background-color: white !important;
}