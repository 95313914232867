.toady {
    color: '#000000';
    font: 'normal normal medium 14px/23px Poppins';
    font-size: 14px;
    opacity: 0.3;
}

.date {
    color: '#000000';
    font: 'normal normal 600 25px/41px Poppins';
    font-size: 25px;
    margin-top: 10px;
}

.dateDiv {
    display: grid;
}

.allTransaction {
    color: '#000000';
    font: 'normal normal 600 19px/31px Poppins';
    font-size: 19px;
    margin-top: 10px;
    font-weight: 600;
}